import React, {useContext} from 'react';
import { graphql } from 'gatsby';
import Link from '@sentry/static-shared/components/link';
import styled from '@emotion/styled';
import {
  colorText,
  mdPink,
  white,
  bgLight,
} from '@sentry/static-shared/utils/css/colors';
import { backgroundChaos } from '@sentry/static-shared/utils/css/patterns';
import Layout from '../components/layouts/layout';
import Layer from '@sentry/static-shared/components/layer-next';
import {
  FlexGrid,
  FlexGridCell,
} from '@sentry/static-shared/components/FlexGrid';
import {
  TextAlign,
  Subtitle,
} from '@sentry/static-shared/components/Typography';
import Search from '@sentry/static-shared/components/search';
import Button from '@sentry/static-shared/components/Button';
import CustomMDXRenderer from '@sentry/static-shared/components/CustomMDXRenderer';
import LinkCard from '@sentry/static-shared/components/link-card';
import MoreHelpSection from '../components/more-help-section';
import HelpCenterContext from '../utils/context';

import StatusResilience from '@sentry/static-shared/components/sections/StatusResilience';

import Line from '@sentry/static-shared/components/Line';
import { useLocation } from "@reach/router";
import { canonicalURL } from '@sentry/static-shared/utils/CanonicalLink';

const IndexPage = ({ data }) => {
  const {
    context: { user },
  } = useContext(HelpCenterContext);

  const locationSlug = useLocation();
  const canonicalLink = canonicalURL('help', locationSlug.pathname);

  return (
    <Layout
      seo={{
        title: 'Help Center',
        canonical: canonicalLink
      }}
    >
      <Layer
        backgroundCSS={backgroundChaos}
        lightType
        backgroundColor={bgLight}
        disableClipping={true}
        paddingBottom
      >
        <TextAlign xs="center">
          <Subtitle>Sentry Support</Subtitle>
          <h1>Need help? Here's the search bar.</h1>
          <StyledSearchMaxWidth>
            <Search
              placeholder="Search support, docs and more"
              config={['zendesk_sentry_articles', 'docs', 'develop', 'blog']}
            />
          </StyledSearchMaxWidth>
          {user && user.isAuthenticated && <StyledPLinks>
            Looking to open a Support Request? Check out our 
            <Button to="https://sentry.zendesk.com/hc" variant="primary">
              Support Portal
            </Button>
          </StyledPLinks>}
        </TextAlign>
      </Layer>

      <Layer backgroundColor={white} clipTop="slopeRight" paddingBottom>

        <StatusResilience headerText="Sentry Status" imgSize="4" />

        <Line variant="line" rotation="right" margins={true} />

        <MoreHelpSection />
      </Layer>

      <Layer backgroundColor={bgLight} clipTop="slopeLeft" paddingBottom>
        <h3>Featured Support Articles</h3>
        <FlexGrid>
          {data.featuredArticles.nodes.map(({ id, title, url }) => {
            return (
              <FlexGridCell md={4} key={id}>
                <LinkCard key={id} to={url} title={title} />
              </FlexGridCell>
            );
          })}
        </FlexGrid>
      </Layer>
    </Layout>
  );
};

export default IndexPage;

const StyledSearchMaxWidth = styled.div`
  max-width: 35rem;
  margin: 1.5rem auto;

  input {
    line-height: 2;
  }
`;

const StyledLink = styled(Link)`
  color: ${colorText};
  transition: color 0.2s;

  &:hover {
    text-decoration: none;
    color: ${mdPink};
  }

  img {
    height: 12rem;
  }
`;

const StyledPLinks = styled.p`
  margin: 1rem 0;

  a.btn {
    margin: 0 0.5rem;
  }
`;

export const query = graphql`
  query {
    allCategory {
      nodes {
        id
        title
        url
        body
        thumbnail {
          id
          publicURL
        }
      }
    }
    featuredArticles: allArticle(filter: { featured: { eq: true } }) {
      nodes {
        id
        title
        url
      }
    }
  }
`;
